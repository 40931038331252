.App {
  height: 100%;
	display: flex;
	flex-direction: column;
  flex: 1;
  background-color: #FFF;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.1);
  box-shadow: inset 0 0 1px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

*::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	background-color: #00b7cd;
	
	/* background-image: -webkit-gradient(linear, 0 0, 0 100%,
                    color-stop(.5, transparent),
                    color-stop(.5, rgba(255, 255, 255, .3)), to(transparent)); */
    /* border-radius: 10px; */
}


@font-face {
  font-family: 'Globerbold';
  src: local('Globerbold'), url(./media/fonts/fontfabric_-_glober_bold-webfont.woff) format('woff');
}

@font-face {
  font-family: 'Globersemibold';
  src: local('Globersemibold'), url(./media/fonts/fontfabric_-_glober_semibold-webfont.woff) format('woff');
}

@font-face {
  font-family: 'Globerregular';
  src: local('Globerregular'), url(./media/fonts/fontfabric_-_glober_regular-webfont.woff) format('woff');
}
